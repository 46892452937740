import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message } from 'react-message-popup'
import Select from "react-select";

import { ENV } from '../common/constants.tsx';
import { DisabledSteps, Form } from './calculate/calculate.tsx';
import { useDebounce } from '../hooks/useDebounce.tsx';
import Progress, { StepTypeEnum } from './calculate/progress.tsx';

interface ICalculateWidgetProps {
  form: Form,
  isInsurerOffersLoading: boolean;
  step: StepTypeEnum;
  stepOptions: Array<{ index: number; value: StepTypeEnum, label: string }>;
  disabledSteps: DisabledSteps;
  onStepChange: (value: StepTypeEnum) => void,
  onSetForm: (field: string, value: any) => void
  onNextStep: () => void
  getOffers: (data: any, callback: any) => void;
  onSetFormValues: (values) => void,
}

const CalculateWidget: React.FC<ICalculateWidgetProps> = (props) => {
  const { form,
    form: { employeesCount, cityNames, newCities = [] },
    isInsurerOffersLoading,
    step, stepOptions,
    disabledSteps,
    onStepChange, onNextStep, onSetForm, getOffers, onSetFormValues } = props;

  const [isTopCitiesLoading, setIsTopCitiesLoading] = useState<boolean>(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);

  const [allCities, setAllCities] = useState<Array<{ name: string }>>([])
  const [isAddCityOpen, setIsAddCityOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [options, setOptions] = useState<any[]>([])

  const isActive = Number(employeesCount) > 0 && (cityNames.length > 0 || newCities.length > 0)

  const handleCityNamesChange = (cityName: string) => (event) => {
    event.preventDefault()
    const isExist = Boolean(cityNames.find(item => item === cityName));

    const result = isExist ? cityNames.filter(item => item !== cityName) : [...cityNames, cityName]

    onSetForm('cityNames', result)
  }

  const handleEmployeesCountChange = (event) => {
    const value = event.target.value.replace(/[^\d]/g, '');

    onSetForm('employeesCount', value)
  }

  const getTopCities = async () => {
    try {
      setIsTopCitiesLoading(true);

      const response = await axios.get(`${ENV.REACT_APP_OZON_WEB}/api/ozon/get-top-cities`)
      setAllCities(response.data)
    } catch (error) {
      message.error('Ошибка загрузки', 4000).then(({ destory }) => {
        setTimeout(() => {
          destory()
        }, 2000)
      });
    } finally {
      setIsTopCitiesLoading(false);
    }
  }

  const handleAddCityOpen = () => {
    setIsAddCityOpen(true)
  }

  const getOptions = async (query: string) => {
    try {
      setIsOptionsLoading(true);

      const response = await axios.get(`${ENV.REACT_APP_OZON_WEB}/api/ozon/get-cities?nameQuery=${query}`)
      const result = response.data.map(item => ({ value: item.name, label: item.name }))
      setOptions(result)
    } catch (error) {
      message.error('Ошибка загрузки', 4000).then(({ destory }) => {
        setTimeout(() => {
          destory()
        }, 2000)
      });
    } finally {
      setIsOptionsLoading(false);
    }
  }

  const debouncedSearch = useDebounce((query) => getOptions(query), 500);

  const handleSearch = (query: string) => {
    setInputValue(query)

    const nameQuery = query ? query[0].toUpperCase() + query.slice(1) : query;

    if (nameQuery.length > 0) {
      debouncedSearch(nameQuery)
    }
  }

  const handleChange = (value) => {
    onSetForm('newCities', value)
    getOptions('')
  }

  const handleCalculate = (event) => {
    event.preventDefault();

    onSetFormValues({
      tariffPlan: 'Standard',
      medicalCareTypes: [],
    })

    const data = {
      cityNames: [...cityNames, ...newCities.map(item => item.value)],
      employeesCount: form.employeesCount,
      tariffPlan: 'Standard',
      medicalCareTypes: [],
    };

    getOffers(data, onNextStep)
  }

  useEffect(() => {
    if (form.newCities) {
      setIsAddCityOpen(form.newCities.length > 0)
    }
  }, [form.newCities])

  useEffect(() => {
    getTopCities()
    getOptions('')
  }, [])

  return (
    <section className="calculate__section">
      <div className="container calculate__container">
        <div className="calculate__progress-block">
          <form className="calculate__form">
            <Progress step={step} stepOptions={stepOptions} disabledSteps={disabledSteps} onStepChange={onStepChange} />
            <h2 className="title calculate__title">Рассчитайте стоимость</h2>
            <input type="text" placeholder="Число сотрудников" value={employeesCount} onChange={handleEmployeesCountChange} />
            <h3 className="title calculate__form-title">В каких городах ваши сотрудники</h3>
            <span className="subtitle calculate__form-subtitle">Необязательно все города, детали уточним позже</span>
            {isTopCitiesLoading && <div className="flex-center">
              <span className="loader loader_dark"></span>
            </div>}
            <div className="calculate__checkbox-menu">
              {allCities.map(item => {
                const isChecked = Boolean(cityNames.find(name => name === item.name));

                return (
                  <div key={item.name} className="checkbox-wrapper-16" onClick={handleCityNamesChange(item.name)}>
                    <label className="checkbox-wrapper">
                      <input type="checkbox" className="checkbox-input" checked={isChecked} />
                      <span className="checkbox-tile">
                        <span className="checkbox-label">{item.name}</span>
                      </span>
                    </label>
                  </div>)
              })}

              {isAddCityOpen || <button className="calculate__add-city" type="button" onClick={handleAddCityOpen}>
                + Добавить другой город
              </button>}

            </div>
            {isAddCityOpen && <div>
              <Select
                options={options}
                inputValue={inputValue}
                onChange={handleChange}
                isMulti
                isSearchable
                onInputChange={handleSearch}
                value={newCities}
                placeholder="Город проживания сотрудников"
                className="multi-select"
                classNamePrefix="select"
                isLoading={isOptionsLoading}
                loadingMessage={() => {
                  return <div className="flex-center">
                    <span className="loader loader_dark"></span>
                  </div>
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 12,
                  border: 'none'
                })}
                noOptionsMessage={() => {
                  return 'Нет данных'
                }}
              />
            </div>}
            <input type="text" placeholder="Город проживания сотрудников" className="calculate__add-city-input" />
            <button type="submit" className="btn" disabled={!isActive} onClick={handleCalculate}>
              {isInsurerOffersLoading ?
                <div className="flex-center">
                  <span className="loader loader_small"></span>
                </div> : 'Рассчитать'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CalculateWidget;
