import React, {
  useState
} from 'react';
import alfastrahovanie from '../images/icons/insurers/alfastrahovanie.svg';
import luchi from '../images/icons/insurers/luchi.svg';
import ingosstrah from '../images/icons/insurers/ingosstrah.svg';
import rosgosstrah from '../images/icons/insurers/rosgosstrah.svg';
import soglasie from '../images/icons/insurers/soglasie.svg';
import vsk from '../images/icons/insurers/vsk.svg';
import zetta from '../images/icons/insurers/zetta.svg';
import agreement from '../images/icons/agreement.svg';
import chevron from '../images/icons/chevron.svg';
import { DisabledSteps, Form } from './calculate/calculate.tsx';
import Modal from './ui/modal/modal.tsx';
import { formatter } from '../services/number-service.ts';
import Progress, { StepTypeEnum } from './calculate/progress.tsx';

const OPTIONS = [
  {
    value: 'Econom',
    label: 'Эконом'
  },
  {
    value: 'Standard',
    label: 'Стандарт'
  },
  {
    value: 'Business',
    label: 'Бизнес'
  }
]

const MEDICAL_CARE_OPTIONS = [
  {
    value: 'Dentistry',
    label: 'Стоматология'
  },
  {
    value: 'EmergencyHospital',
    label: 'Госпитализация'
  }
]

const EMPTY_OFFER_PRICE = 0;

interface ITariffWidgetProps {
  form: Form,
  insurerOffers: any;
  isInsurerOffersLoading: boolean;
  step: StepTypeEnum;
  stepOptions: Array<{ index: number; value: StepTypeEnum, label: string }>;
  disabledSteps: DisabledSteps;
  onStepChange: (value: StepTypeEnum) => void,
  onSetForm: (field: string, value: any) => void,
  onSetFormValues: (values) => void,
  onNextStep: () => void,
  getOffers: (data: any) => void
}

const TariffWidget: React.FC<ITariffWidgetProps> = (props) => {
  const { form, form: { medicalCareTypes },
    insurerOffers, isInsurerOffersLoading, step, stepOptions, disabledSteps,
    onStepChange, onNextStep, onSetForm, getOffers, onSetFormValues } = props;
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<any>();

  const handleClaim = (item) => (event) => {
    event.preventDefault();

    const values = {
      avgPrice: item.avgPrice,
      insurerName: item.insurerName
    }
    onSetFormValues(values)
    onNextStep()
  }

  const handleTariffPlanChange = (value) => () => {
    const values = {
      tariffPlan: value,
      medicalCareTypes: []
    };
    onSetFormValues(values)

    const data = {
      cityNames: [...form.cityNames, ...form.newCities.map(item => item.value)],
      employeesCount: +form.employeesCount,
      medicalCareTypes: [],
      tariffPlan: value,
    };

    getOffers(data)
  }

  const handleMedicalCareTypesChange = (value: string) => () => {
    const isExist = Boolean(medicalCareTypes.find(item => item === value));
    const result = isExist ? medicalCareTypes.filter(item => item !== value) : [...medicalCareTypes, value]

    onSetForm('medicalCareTypes', result);

    const data = {
      cityNames: [...form.cityNames, ...form.newCities.map(item => item.value)],
      employeesCount: +form.employeesCount,
      tariffPlan: form.tariffPlan,
      medicalCareTypes: result,
    };

    getOffers(data)
  }

  const handleDocumentsModalOpen = (item) => (event) => {
    event.preventDefault();

    setActiveItem(item)
    setIsDocumentsModalOpen(true)
  }

  const handleDocumentsModalClose = () => {
    setActiveItem(undefined)
    setIsDocumentsModalOpen(false)
  }

  const getPrice = (price: string) => {
    const formattedPrice = formatter(+price);

    return `${formattedPrice} ₽`
  }

  const getInsurerIcon = (insurerName: string) => {
    if (insurerName.match(/альфастрахование/gi)) return alfastrahovanie;
    if (insurerName.match(/Ингосстрах/gi)) return ingosstrah;
    if (insurerName.match(/Лучи/gi)) return luchi;
    if (insurerName.match(/ВСК/gi)) return vsk;
    if (insurerName.match(/Зетта/gi)) return zetta;
    if (insurerName.match(/Росгосстрах/gi)) return rosgosstrah;
    if (insurerName.match(/Согласие/gi)) return soglasie;
  }

  return (
    <section className="tariff__section">
      <div className="container tariff__container">
        <div className="tariff__progress-block">
          <form className="tariff__form">
            <Progress step={step} stepOptions={stepOptions} disabledSteps={disabledSteps} onStepChange={onStepChange} />
            <h2 className="title tariff__title">Выберите тариф</h2>
            <input name="insurerName" hidden />
            <div className="tariff__wrapper">
              <div className="custom-radio-menu">
                {OPTIONS.map((item) => {
                  return (
                    <div key={item.value}>
                      <input
                        type="radio"
                        id={item.value}
                        name="options"
                        value={item.value}
                        checked={form.tariffPlan === item.value}
                        onChange={handleTariffPlanChange(item.value)}
                      />
                      <label htmlFor={item.value}>{item.label}</label>
                    </div>
                  )
                })}
              </div>
              <div className="tariff__clinic">
                <div className="tariff__clinic-label">Поликлиника и врач на дом</div>
                <div className="tariff__clinic-value">Включено</div>
              </div>
              <div className="tariff__checkbox-menu">
                {MEDICAL_CARE_OPTIONS.map(item => {
                  const isChecked = Boolean(form.medicalCareTypes.find(type => type === item.value));

                  return (
                    <div key={item.value} className="checkbox-wrapper-14">
                      <label htmlFor="s1-14">{item.label}</label>
                      <input
                        id="s1-14"
                        type="checkbox"
                        className="switch"
                        checked={isChecked}
                        onChange={handleMedicalCareTypesChange(item.value)}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            {insurerOffers.length === 0 ?
              <div className="tariff__no-data">
                <div className="tariff__no-data-title">
                  Не смогли подобрать<br /> готовое решение для&nbsp;вас
                </div>
                <div className="tariff__no-data-subtitle">
                  Но мы можем связаться с&nbsp;вами и&nbsp;подобрать индивидуальное решение
                </div>
                <button className="btn" onClick={handleClaim(EMPTY_OFFER_PRICE)}>Оставить заявку</button>
              </div> :
              <>
                <h3 className="subtitle tariff__subtitle">Выберите страховую компанию</h3>
                {isInsurerOffersLoading ?
                  <div className="tariff__loader flex-center">
                    <span className="loader"></span>
                  </div> :
                  <div className="tariff__swiper">
                    {insurerOffers.map((item) => {
                      const title = `${item.avgPrice.toLocaleString('ru-RU')} ₽`;

                      return (
                        <div key={`${item.insurerName}+${item.avgPrice}`} className="tariff__block">
                          <div className="tariff__block-top">
                            <div className="tariff__block-icon">
                              <img src={getInsurerIcon(item.insurerName)} alt="icon" />
                            </div>
                            <span title={title}>{`от ${getPrice(item.avgPrice)}`}</span>
                          </div>
                          <div className="tariff__block-text">
                            <p>{item.insurerName}</p>
                          </div>
                          <div className="tariff__block-bottom">
                            <button className="btn tariff__modal-btn" onClick={handleClaim(item)}>Оформить</button>
                            <button className="icon-block tariff__icon-block" onClick={handleDocumentsModalOpen(item)}>
                              <img src={agreement} alt="icon" />
                            </button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                }
              </>}
          </form>
        </div>
      </div>
      {activeItem && activeItem.fileLinks && <Modal
        isOpen={isDocumentsModalOpen}
        onRequestClose={handleDocumentsModalClose}
      >
        <div>
          <div className="tariff__documents-title">
            Документы
          </div>
          <div className="tariff__documents-list">{activeItem.fileLinks.map((file) => {
            if (file) {
              return <a href={file.link} target="_blank" rel="noreferrer" className="tariff__document-link">
                <div className="icon-block tariff__icon-block">
                  <img src={agreement} alt="icon" />
                </div>
                <div className="tariff__document-name">
                  <span className="tariff__document-title">{file.description}</span>
                  <span className="tariff__document-extension">{file.fileExtension}</span>
                </div>
                <div className="tariff__document-chevron-icon">
                  <img src={chevron} alt="icon" />
                </div>
              </a>
            }
            return null
          })}
          </div>
          <button className="btn btn_secondary" onClick={handleDocumentsModalClose}>Закрыть</button>
        </div>
      </Modal>}
    </section>
  );
};

export default TariffWidget;
