import React from 'react';

import check from '../../images/icons/check.svg';
import back from '../../images/icons/back.svg';
import { DisabledSteps } from './calculate';

export enum StepTypeEnum {
  price = "price",
  tariff = "tariff",
  // data = "data",
  contacts = "contacts"
}

export const STEPS_OPTIONS = [
  {
    index: 1,
    value: StepTypeEnum.price,
    label: 'Стоимость',
  },
  {
    index: 2,
    value: StepTypeEnum.tariff,
    label: 'Тариф',
  },
  // {
  //   index: 3,
  //   value: StepTypeEnum.data,
  //   label: 'Данные',
  // },
  {
    index: 3,
    value: StepTypeEnum.contacts,
    label: 'Контакты',
  },
]

interface IProgressProps {
  step: StepTypeEnum;
  stepOptions: Array<{ index: number; value: StepTypeEnum, label: string }>;
  disabledSteps: DisabledSteps
  onStepChange: (value: StepTypeEnum) => void,
}

const Progress: React.FC<IProgressProps> = (props) => {
  const { step, stepOptions, disabledSteps, onStepChange } = props;

  const getPreviousStep = () => {
    const stepKeys = Object.keys(StepTypeEnum);
    const stepIndex = stepKeys.indexOf(step);
    const previousStepIndex = stepIndex - 1;
    const previousStep = stepKeys[previousStepIndex] as StepTypeEnum;

    return previousStep;
  }

  const handleStepShange = (value: StepTypeEnum) => (event) => {
    event.preventDefault();

    onStepChange(value)
  }

  return (
    <div className="progress">
      {step === StepTypeEnum.price || <button className="progress__back-btn">
        <img src={back} alt="back" onClick={handleStepShange(getPreviousStep())} />
      </button>}
      <div className="progress__steps">
        {stepOptions.map((item) => {
          const isDisabled = disabledSteps[item.value];

          return <button
            key={item.value}
            className={`progress__step ${isDisabled ? '' : 'progress__step_active'}`}
            onClick={handleStepShange(item.value)}
            disabled={isDisabled}
          >
            {isDisabled ||
              <img className="progress__step-icon" src={check} alt="check" width={10} height={7} />
            }
            {item.label}
          </button>
        })}
      </div>
    </div>
  )
};

export default Progress;
