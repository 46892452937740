import React from 'react';
import timerLogo from '../images/icons/timer-logo.svg';
import flash from '../images/icons/flash.svg';
import mainMobile from '../images/all-img/main-mobile.png';

const MainSection: React.FC = () => {

  const handleClick = () => {
    if (window) {
      const element = window.document.getElementById('calculate-section');

      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <section className="main__section">
      <div className="main__image"></div>
      <div className="container main__container">
        <div className="main__top">
          <div className="main__top-text-block">
            <h1 className="main__title">ДМС для вас и&nbsp;ваших сотрудников</h1>
            <p className="main__subtitle">Сохраните здоровый коллектив</p>
          </div>
          <div className="main__image-mobile">
            <img src={mainMobile} alt="main" />
          </div>
          <button className="btn main__btn" onClick={handleClick}>Рассчитать стоимость</button>
        </div>
        <div className="main__bottom">
          <div className="main__block">
            <div className="icon-block main__icon-block">
              <img src={timerLogo} alt="timer" />
            </div>
            <div className="main__text-block">
              <h3 className="title">Быстрое оформление</h3>
              <p className="subtitle">За несколько дней</p>
            </div>
          </div>
          <div className="main__line" />
          <div className="main__block">
            <div className="icon-block main__icon-block">
              <img src={flash} alt="flash" />
            </div>
            <div className="main__text-block">
              <h3 className="title">Надёжные партнёры</h3>
              <p className="subtitle">И лучшие клиники</p>
            </div>
          </div>
          <div className="main__line" />
          <div className="main__block">
            <div className="icon-block main__icon-block">
              <img src={flash} alt="flash" />
            </div>
            <div className="main__text-block">
              <h3 className="title">Гибкие условия</h3>
              <p className="subtitle">И персональные настройки</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
